<template>
<CContainer>
  <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <CRow class="justify-content-between">
              <CCol col="3">
                <h3>Estadisticas</h3>
              </CCol>
              <CCol col="4">
                <button class="btn btn-success" @click="printFacture()">Descargar Reporte PDF</button>
                <button class="btn btn-warning" @click="handleClick()">Atras</button>
              </CCol>
            </CRow>
          </CCardHeader>
        </CCard>
      </CCol>
   </CRow>
   <div id="captura-pdf" ref="DownloadComp" >
     
     <CRow>
      <CCol col="12">
        <CCard>
          <CCardBody>
            <CRow class="justify-content-between">
              <CCol col="8">
                <h1 class="text-center">Estadísticas Mailing Masivo</h1>
                <div class="small text-muted text-center">{{dataConsultaChart.fechainicio}} - {{dataConsultaChart.fechafin}}</div>
              </CCol>
              <CCol col="4">
                <div class="c-avatar" style="width:70px;height:70px;">
                  <img
                    :src="obtenerLogo"
                    class="c-avatar-img"
                    :style="fondoLogo"
                  />
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
   </CRow>
      <ChartCirculos :dataChartCirculos="dataChartCirculos" :dataConsultaChart="dataConsultaChart" />
      <ChartMensajes :dataChartMensajes="dataChartMensajes" :dataConsultaChart="dataConsultaChart" />
      <ChartMensajesMes :dataChartMensajesMes="dataChartMensajesMes" :dataConsultaChart="dataConsultaChart" />
      <ChartMensajesSemanal :dataChartMensajesSemanal="dataChartMensajesSemanal" :dataConsultaChart="dataConsultaChart" />
      <ChartMensajesCampana :dataChartMensajesCampana="dataChartMensajesCampana" :dataConsultaChart="dataConsultaChart" />
      <ChartMensajesSubcuenta :dataChartMensajesSubcuenta="dataChartMensajesSubcuenta" :dataConsultaChart="dataConsultaChart" />
      <ChartLecturasDominios :dataChartLecturasDominios="dataChartLecturasDominios" :dataConsultaChart="dataConsultaChart" />
      <ChartLecturasNavegador :dataChartLecturasNavegador="dataChartLecturasNavegador" :dataConsultaChart="dataConsultaChart" />
      <ChartLecturasSistemaOperativo :dataChartLecturasSistemaOperativo="dataChartLecturasSistemaOperativo" :dataConsultaChart="dataConsultaChart" />
      <ChartLecturasPais :dataChartLecturasPais="dataChartLecturasPais" :dataConsultaChart="dataConsultaChart" />
      <ChartMapaColombia :dataChartMapaColombia="dataChartMapaColombia" :dataConsultaChart="dataConsultaChart" />
   </div>
  <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
  </div>
</CContainer>

</template>

<script>
import html2canvas from 'html2canvas/dist/html2canvas.js'
import ChartMensajes from './charts/ChartMensajes.vue'
import ChartMensajesMes from './charts/ChartMensajesMes.vue'
import ChartCirculos from './charts/ChartCirculos.vue'
import ChartMensajesSemanal from './charts/ChartMensajesSemanal.vue'
import ChartMensajesCampana from './charts/ChartMensajesCampana.vue'
import ChartMensajesSubcuenta from './charts/ChartMensajesSubcuenta.vue'
import ChartLecturasDominios from './charts/ChartLecturasDominios.vue'
import ChartLecturasNavegador from './charts/ChartLecturasNavegador.vue'
import ChartLecturasPais from './charts/ChartLecturasPais.vue'
import ChartLecturasSistemaOperativo from './charts/ChartLecturasSistemaOperativo.vue'
import ChartMapaColombia from './charts/ChartMapaColombia.vue'
import { jsPDF } from "jspdf"
//import ChartMapa from './charts/ChartMapa.vue'
export default {
  name: 'EstadisticasMail',
  components: {
    ChartMensajes,
    ChartMensajesMes,
    ChartCirculos,
    ChartMensajesSemanal,
    ChartMensajesCampana,
    ChartMensajesSubcuenta,
    ChartLecturasDominios,
    ChartLecturasNavegador,
    ChartLecturasPais,
    ChartLecturasSistemaOperativo,
    ChartMapaColombia,
  },
  data () {
    return {
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      mensaje_success:'Grupo Creado Correctamente.',
      dataChartMensajes:{},
      dataChartMensajesMes:{},
      selected:'Dia',
      dataChartCirculos:{},
      dataChartMensajesSemanal:{},
      dataChartMensajesCampana:{},
      dataChartMensajesSubcuenta:{},
      dataChartLecturasDominios:[],
      dataChartLecturasNavegador:[],
      dataChartLecturasPais:[],
      dataChartLecturasSistemaOperativo:[],
      dataChartMapaColombia:{},
    }
  },
  props:{
    dataConsultaChart: Object
  },
  methods: {
     consultaDataCharts(){
          this.$store.dispatch('auth/loading_http','visible');
          this.dataConsultaChart.url = "/mail/obtener/chart"
          this.$http.post('/mail/obtener/chart',this.dataConsultaChart).then(response => {
             const dataTemp = response.data;
             const data = JSON.parse(JSON.stringify(dataTemp))
             this.dataChartMensajes = data['data1'];
             this.dataChartMensajesMes = data['data2'];
             this.dataChartCirculos = data['data4'];
             this.dataChartMensajesSemanal = data['data5'];
             this.dataChartMensajesCampana = data['data6'];
             this.dataChartMensajesSubcuenta = data['data7'];
             this.dataChartLecturasDominios = data['data8'];
             this.dataChartLecturasNavegador = data['data9'];
             this.dataChartLecturasPais = data['data10'];
             this.dataChartLecturasSistemaOperativo = data['data11'];
             this.dataChartMapaColombia = data['data12'];
          }).catch((e)=>{
            this.mostrarNotificacionError ++;
          }).finally(() =>this.$store.dispatch('auth/loading_http','hidden')); 
     },
     async printFacture() {
      try {
          this.$store.dispatch('auth/loading_http','visible');
          let width_antes = document.getElementById('captura-pdf').offsetWidth;
          let height_antes = document.getElementById('captura-pdf').offsetHeight;
          var element = document.getElementById('captura-pdf');
          if (width_antes < 973) {
              document.getElementById('captura-pdf').style.width = '973px';
              document.getElementById('captura-pdf').style.cursor = 'se-resize';
          }
         
          var element = document.getElementById('captura-pdf');
          var width = element.offsetWidth;
          var height = element.offsetHeight;
          console.log(width_antes);
          console.log(width);
          var pdf = width_antes < 973 ? new jsPDF({orientation: 'portrait',unit:'pt', format:[(1100),(height/4)+height]}) : new jsPDF({orientation: 'portrait',unit:'pt', format:[(width*0.85),(height*0.85)]});
          
          const canvas = await html2canvas(element);
          var image = canvas.toDataURL('image/png');
          pdf.addImage(image, 'PNG',2,2);
          pdf.save(`Estadisticas_${this.dataConsultaChart.fechainicio}_${this.dataConsultaChart.fechafin}.pdf`);
          if (width_antes < 973) {
              document.getElementById('captura-pdf').style.width = width_antes+'px';
              document.getElementById('captura-pdf').style.height = height_antes+'px';
              document.getElementById('captura-pdf').style.cursor = 'se-resize';
          }
          this.$store.dispatch('auth/loading_http','hidden');
      } catch (error) {
          console.error('Error generating PDF:', error);
          this.$store.dispatch('auth/loading_http','hidden');
      }
    },
      handleClick: function() {
        this.$root.$emit('clickedSomething',1)
      }
  },
  mounted(){
    setTimeout(() => {
      this.consultaDataCharts();
    }, 100);
  },
  computed: {
    obtenerLogo(){
      return this.$store.state.auth.logo_web;
    },
    fondoLogo(){
      try {
        let url = this.$store.state.auth.logo_web;
        if (url.search("img/avatars") >= 0) {
          return 'background:white;'; 
        }else{
          return ''; 
        }
      } catch (error) {
        return ''; 
      }
    },
  }
}
</script>
