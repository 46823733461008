<template>
 <CRow>
      <CCol col="12">
        <CCard>
          <CCardBody>
             <br>
             <CRow>
              <CCol col="12">
                    <CButton
                      color="dark"
                      variant="outline"
                      square
                      size="sm"
                      @click="downloadFile()"
                    >
                      Descargar
                    </CButton>
              </CCol>
               <CCol col="12">
                   <h4 class="card-title mb-0 text-center">Detalle Lectura Paises</h4>
                    <div class="small text-muted text-center">{{dataConsultaChart.fechainicio}} - {{dataConsultaChart.fechafin}}</div>
               </CCol>
               <CCol col="12">
                      <table class="table">
                        <tr>
                          <th>Pais</th>
                          <th>Cantidad lecturas</th>
                          <th>Porcentaje</th>
                        </tr>
                        <tr v-for="(item, index) in calcularListadoLecturas">
                          <td>{{item['pais']}}</td>
                          <td>{{creditosConversorMoneda(item['cantidad'])}}</td>
                          <td>{{creditosConversorMoneda(item['porcentaje'])}} %</td>
                        </tr>
                        <tr>
                           <td></td>
                           <td><strong>Total:</strong> {{creditosConversorMoneda(totalCantidad)}}</td>
                           <td><strong>Total:</strong> {{creditosConversorMoneda(totalPorcentaje)}} %</td>
                        </tr>
                     </table>
               </CCol>
             </CRow>
          </CCardBody>
        </CCard>
      </CCol>
   </CRow>
  
</template>

<script>
import exportFromJSON from "export-from-json"; 
export default {
  name: 'ChartLecturasPais',
  components: {
  },
  data () {
    return {
      items: [],
      fields: [
        { key: 'dia',label:'Dia'},
        { key: 'mensajes',label:'Mensajes Enviados',},
        { key: 'credito',label:'Creditos Consumidos'},
      ],
      activePage: 1,
      dataListDomain:[],
    }
  },
  props:{
    dataChartLecturasPais: Array,
    dataConsultaChart: Object,
  },
  methods: {
    creditosConversorMoneda(value){
      const formatterDolar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
      })
      return (formatterDolar.format(value)).replace('$','');
    },
    downloadFile() {
      const data = this.dataChartLecturasPais;
      const fileName = "ChartLecturasPais";
      const exportType = exportFromJSON.types.csv;
      if (data) exportFromJSON({ data, fileName, exportType });
    },
  },
  computed: {
    totalCantidad(){
      let sum = 0;
      this.dataChartLecturasPais.forEach(element => {
        sum += element.cantidad;
      });
      return sum;
    },
    totalPorcentaje(){
      let sum = 0;
      this.dataChartLecturasPais.forEach(element => {
        sum += element.porcentaje;
      });
      return sum;
    },
    calcularListadoLecturas(){
        let data = [];
        let contador = 0;
        let cantidad_otros = 0;
        let porcentaje_otros = 0;
        for (const iterator of this.dataChartLecturasPais) {
            if (contador < 9) {
                data.push(iterator);
            }else{
              cantidad_otros += iterator.cantidad;
              porcentaje_otros += iterator.porcentaje;
            }
            contador++;
        }
        if (cantidad_otros > 0) {
            data.push({pais: "Otros", cantidad: cantidad_otros, porcentaje: porcentaje_otros});
        }
        return data;
    }
  }
}
</script>
