<template>
 <CRow>
      <CCol col="12">
        <CCard>
          <CCardBody>
             <CRow>
               <CCol col="12">
                 <CRow>
                      <CCol col="12">
                        <h4 class="card-title mb-0 text-center">Detalles Mensajes</h4>
                        <div class="small text-muted text-center">{{dataConsultaChart.fechainicio}} - {{dataConsultaChart.fechafin}}</div>
                      </CCol>
                    </CRow>
               </CCol>
             </CRow>
             <CRow>
                <CCol col="3">
                  <vc-donut
                        background="white" foreground="#eee"
                        :size="200" unit="px" :thickness="30"
                        has-legend legend-placement="top"
                        :sections="sectionsEnviados" :total="total"
                        :start-angle="0" :auto-adjust-text-size="true"
                        >
                        <h1>{{enviados}}%</h1>
                        <div class="small text-muted text-center"><strong>{{creditosConversorMoneda(this.dataChartCirculos['enviados'])}}</strong></div>
                  </vc-donut>
                </CCol>
                <CCol col="3">
                  <vc-donut
                        background="white" foreground="#eee"
                        :size="200" unit="px" :thickness="30"
                        has-legend legend-placement="top"
                        :sections="sectionsErrados" :total="total"
                        :start-angle="0" :auto-adjust-text-size="true"
                        >
                        <h1>{{errados}}%</h1>
                        <div class="small text-muted text-center"><strong>{{creditosConversorMoneda(this.dataChartCirculos['errados'])}}</strong></div>
                  </vc-donut>
                </CCol>
                <CCol col="3">
                  <vc-donut
                        background="white" foreground="#eee"
                        :size="200" unit="px" :thickness="30"
                        has-legend legend-placement="top"
                        :sections="sectionsLecturas" :total="total"
                        :start-angle="0" :auto-adjust-text-size="true"
                        >
                        <h1>{{lecturas}}%</h1>
                        <div class="small text-muted text-center"><strong>{{creditosConversorMoneda(this.dataChartCirculos['lecturas'])}}</strong></div>
                  </vc-donut>
                </CCol>
                <CCol col="3">
                  <vc-donut
                        background="white" foreground="#eee"
                        :size="200" unit="px" :thickness="30"
                        has-legend legend-placement="top"
                        :sections="sectionsDesSuscripciones" :total="total"
                        :start-angle="0" :auto-adjust-text-size="true"
                        >
                        <h1>{{des_suscripciones}}%</h1>
                        <div class="small text-muted text-center"><strong>{{creditosConversorMoneda(this.dataChartCirculos['des_suscripciones'])}}</strong></div>
                  </vc-donut>
                </CCol>
              </CRow>
              <CRow class="justify-content-center">
                <CCol col="3">
                  <vc-donut
                        background="white" foreground="#eee"
                        :size="200" unit="px" :thickness="30"
                        has-legend legend-placement="top"
                        :sections="sectionsClicks" :total="total"
                        :start-angle="0" :auto-adjust-text-size="true"
                        >
                        <h1>{{mail_clicks}}%</h1>
                        <div class="small text-muted text-center"><strong>{{creditosConversorMoneda(this.dataChartCirculos['mail_clicks'])}}</strong></div>
                  </vc-donut>
                </CCol>
                <CCol col="3">
                  <vc-donut
                        background="white" foreground="#eee"
                        :size="200" unit="px" :thickness="30"
                        has-legend legend-placement="top"
                        :sections="sectionsTotalprecio" :total="totalPrecioP"
                        :start-angle="0" :auto-adjust-text-size="true"
                        >
                        <h1>{{totalPrecio}}%</h1>
                        <div class="small text-muted text-center"><strong>{{creditosConversorMoneda(this.dataChartCirculos['total_precio'])}}</strong></div>
                  </vc-donut>
                </CCol>
              </CRow>
          </CCardBody>
        </CCard>
      </CCol>
   </CRow>
  
</template>

<script>
import 'vue-css-donut-chart/dist/vcdonut.css';
export default {
  name: 'ChartCirculos',
  components: {
  },
  data () {
    return {
    }
  },
  props:{
    dataChartCirculos: Object,
    dataConsultaChart: Object,
  },
  methods: {
    handleSectionClick(section, event) {
        console.log(`${section.label} clicked.`);
    },
    creditosConversorMoneda(value){
      const formatterDolar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
      })
      let f =  (formatterDolar.format(value)).replace('$','');
      return (f.replace('.00',''));
    },
  },
  computed: {
    enviados () {
      try {
        return ((this.dataChartCirculos['enviados']*100)/this.dataChartCirculos['total']).toFixed(2);
      } catch (error) {
        return 0;
      }
    },
    sectionsEnviados(){
      try {
        return [{ label: 'Mensajes Enviados', value: this.dataChartCirculos['enviados'], color: '#2AACDA' }]
      } catch (error) {
        return [{ label: 'Mensajes Enviados', value: 0, color: '#2AACDA' }]
      }
    },
    total(){
      try {
        return this.dataChartCirculos['total']
      } catch (error) {
        return 100;
      }
    },
    errados () {
      try {
        return ((this.dataChartCirculos['errados']*100)/this.dataChartCirculos['total']).toFixed(2);
      } catch (error) {
        return 10;
      }
    },
    sectionsErrados(){
      try {
        return [{ label: 'Mensajes Errados', value: this.dataChartCirculos['errados'], color: 'yellow' }]
      } catch (error) {
        return [{ label: 'Mensajes Errados', value: 0, color: 'yellow' }]
      }
    },
    lecturas () {
      try {
        return ((this.dataChartCirculos['lecturas']*100)/this.dataChartCirculos['total']).toFixed(2);
      } catch (error) {
        return 10;
      }
    },
    des_suscripciones () {
      try {
        return ((this.dataChartCirculos['des_suscripciones']*100)/this.dataChartCirculos['total']).toFixed(2);
      } catch (error) {
        return 10;
      }
    },
    mail_clicks () {
      try {
        return ((this.dataChartCirculos['mail_clicks']*100)/this.dataChartCirculos['total']).toFixed(2);
      } catch (error) {
        return 10;
      }
    },
    sectionsLecturas(){
      try {
        return [{ label: 'Mensajes Leidos', value: this.dataChartCirculos['lecturas'], color: '#AD8A4F' }]
      } catch (error) {
        return [{ label: 'Mensajes Leidos', value: 0, color: '#AD8A4F' }]
      }
    },
    sectionsDesSuscripciones(){
      try {
        return [{ label: 'Mensajes Des_suscripciones', value: this.dataChartCirculos['des_suscripciones'], color: '#F74747' }]
      } catch (error) {
        return [{ label: 'Mensajes Des_suscripciones', value: 0, color: '#F74747' }]
      }
    },
    sectionsClicks(){
      try {
        return [{ label: 'Mensajes Clicks', value: this.dataChartCirculos['mail_clicks'], color: '#a54f4f' }]
      } catch (error) {
        return [{ label: 'Mensajes Clicks', value: 0, color: '#a54f4f' }]
      }
    },
    totalPrecio () {
      try {
        return 100;
      } catch (error) {
        return 0;
      }
    },
    totalPrecioP () {
      try {
        return this.dataChartCirculos['total_precio'];
      } catch (error) {
        return 0;
      }
    },
    sectionsTotalprecio(){
      try {
        return [{ label: 'Creditos Consumidos', value: this.dataChartCirculos['total_precio'], color: '#4DBD74' }]
      } catch (error) {
        return [{ label: 'Creditos Consumidos', value: 0, color: '#4DBD74' }]
      }
    },
  }
}
</script>
