<template>
   <CRow>
      <CCol col="12">
        <CCard>
          <CCardBody>
             <br>
             <CRow>
               <CCol col="12">
                   <h4 class="card-title mb-0 text-center">Detalle Envios por Departamento de Colombia</h4>
                    <div class="small text-muted text-center">{{dataConsultaChart.fechainicio}} - {{dataConsultaChart.fechafin}}</div>
               </CCol>
               <CCol col="12" :style="{ height: style_mapa_height + 'px',width: style_mapa_width + 'px' }">
                <div style="height:100%">
                  <vue-datamaps
                    labels
                    :geography-config="geographyConfig"
                    :data="obtenerDataMapa"
                    :fills="fills"
                    :height="100"
                    :width="2"
                    popupTemplate
                    @custom:popup="popupTemplate"
                    :setProjection="setProjection('colombia')"
                    >
                    <div slot="hoverinfo" class="hoverinfo" style="white-space: pre-line;">
                        <b>Departamento</b>: {{ popupData.departamento }}
                        Cantidad de Mensajes {{ popupData.cantidad }}
                    </div>
                    
                  </vue-datamaps>
                  <!--<input type="hidden" :value="obtenerDataMapa">-->
                </div>
               </CCol>
             </CRow>
             <CRow>
              <CCol col="12">
                    <CButton
                      color="dark"
                      variant="outline"
                      square
                      size="sm"
                      @click="downloadFile()"
                    >
                      Descargar Tabla
                    </CButton>
              </CCol>
              <CCol col="12">
                <CDataTable
                  hover
                  striped
                  :items="obtenerDataMapaTabla"
                  :fields="fields_ciudades"
                  :items-per-page="20"
                  :pagination="{ doubleArrows: false, align: 'center' }"
                  @page-change="pageChange"
                >
                </CDataTable>
              </CCol>
              <CCol col="12">
                <div class="small text-muted text-center">La información de ciudad y país mostrada se obtiene a través de la dirección IP proporcionada por el  proveedor de servicios de Internet del cliente final. Tenga en cuenta que la asignación de IP puede no ser precisa y no representa necesariamente su ubicación física actual</div>
              </CCol>
             </CRow>
          </CCardBody>
        </CCard>
      </CCol>
   </CRow>
</template>

<script>
import { VueDatamaps } from '../../../../custom_modules/vue-datamaps';
import * as d3 from 'd3v4';
import exportFromJSON from "export-from-json"; 
export default {
  name: 'ChartMapaColombia',
  components: {
    VueDatamaps
  },
  data () {
    let url = this.$apiAdress+'/dist/data/col.json';    
    return {
      geographyConfig: {
        dataUrl: url,
        //popupOnHover: false,
        //highlightOnHover: false,
        //borderWidth: 3
      },
      scope: 'col',
      fills: {
        defaultFill: '#ABDDA4',
        gt50: d3.schemeCategory20[Math.floor(Math.random() * 20)],
        eq50: d3.schemeCategory20b[Math.floor(Math.random() * 20)],
        lt25: d3.schemeCategory20c[Math.floor(Math.random() * 20)],
        gt75: d3.schemeCategory20[Math.floor(Math.random() * 20)],
        lt50: d3.schemeCategory10[Math.floor(Math.random() * 10)],
        eq0: d3.schemeCategory10[Math.floor(Math.random() * 1)],
        pink: '#0fa0fa',
        gt500: d3.schemeCategory10[Math.floor(Math.random() * 1)]
      },
      data: {
        //'CO.CU': { fillKey: 'lt50' },
        //'CO.AM': { fillKey: 'lt50' }
      },
      popupData: {
        departamento: '',
        cantidad: '',
      },
      style_mapa_height: 1000,
      style_mapa_width: 730,
      items_ciudades: [],
      fields_ciudades: [
        { key: "municipio", label: "Ciudad" },
        { key: "departamento", label: "Departamento" },
        { key: "cantidad",label: "Clicks"},
      ],
    }
  },
  props:{
    dataChartMapaColombia: Object,
    dataConsultaChart: Object,
  },
  computed: {
    obtenerDataMapa(){
      if (this.dataChartMapaColombia != null) {
        return this.dataChartMapaColombia.data1;
      }
      return [];
    },
    obtenerDataMapaTabla(){
      if (this.dataChartMapaColombia != null) {
        return this.dataChartMapaColombia.data2;
      }
      return [];
    },
  },
  mounted() {
    //this.obtenerDataMapa();
    
  },
  methods: {
    popupTemplate ({ datum }) {
      if (datum == undefined) {
        this.popupData = {
          departamento: '',
          cantidad: 0,
        }
      }else{
        this.popupData = {
          departamento: datum.region,
          cantidad: datum.contador,
        }
      }
      //this.popupData.name = `Bubble for ${datum.name}`
    },
    setProjection (type) {
      const createProjection = {
        korea: function (d3, element) {
          const projection = d3.geoMercator().center([0, 0])
            .rotate([-128, -36])
            .scale(element.offsetWidth * 6)
            .translate([element.offsetWidth / 2, element.offsetHeight / 2])
          const path = d3.geoPath().projection(projection)
          return { projection, path }
        },
        zoom: function (d3, element) {
          var projection = d3.geoEquirectangular()
            .center([10, -3])
            .rotate([4.4, 0])
            .scale(element.offsetHeight * 0.6)
            .translate([element.offsetWidth / 2, element.offsetHeight / 2])
          var path = d3.geoPath()
            .projection(projection)
          return { path: path, projection: projection }
        },
        colombia: function (d3, element) {
          var projection = d3.geoEquirectangular()
            .center([1, 7])
            .rotate([74, 3.3])
            .scale(700 * 4)
            .translate([element.offsetWidth / 2, element.offsetHeight / 2])
          var path = d3.geoPath()
            .projection(projection)
          return { path: path, projection: projection }
        }
      }
      return createProjection[type]
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    downloadFile() {
      const data = this.dataChartMapaColombia.data2;
      const fileName = "ChartMapaColombia";
      const exportType = exportFromJSON.types.csv;
      if (data) exportFromJSON({ data, fileName, exportType });
    },
  }
}
</script>

<style>
.map {
    height: 100% !important;
}
</style>
