<template>
 <CRow>
      <CCol col="12">
        <CCard>
          <CCardBody>
             <CRow>
               <CCol col="12">
                 <CRow>
                      <CCol col="12">
                        <h4 class="card-title mb-0 text-center">Mensajes Enviados Por Campañas</h4>
                        <div class="small text-muted text-center">{{dataConsultaChart.fechainicio}} - {{dataConsultaChart.fechafin}}</div>
                      </CCol>
                    </CRow>
                    <div id="chart1">
                        <apexchart type="pie" :options="defaultOptionsDonut" :series="defaultDatasetsDonut"></apexchart>
                      </div>
                      <br>
                      <div id="chart">
                        <apexchart type="line" :options="defaultOptions" :series="defaultDatasets"></apexchart>
                      </div>  
               </CCol>
             </CRow>
             <br>
             <CRow>
               <CCol col="12">
                   <h4 class="card-title mb-0 text-center">Detalle Mensajes Enviados Por Campañas</h4>
                    <div class="small text-muted text-center">{{dataConsultaChart.fechainicio}} - {{dataConsultaChart.fechafin}}</div>
               </CCol>
               <CCol col="12">
                      <table class="table">
                        <tr>
                          <th>Semanas</th>
                          <th>Mensajes Enviados</th>
                          <th>Creditos Consumidos</th>
                        </tr>
                        <tr v-for="(item, index) in items">
                          <td>{{item['campana']}}</td>
                          <td>{{creditosConversorMoneda(item['mensajes'])}}</td>
                          <td>{{creditosConversorMoneda(item['credito'])}}</td>
                        </tr>
                        <tr>
                           <td></td>
                           <td><strong>Total:</strong> {{creditosConversorMoneda(totalCelulares)}}</td> </td>
                           <td><strong>Total:</strong> {{creditosConversorMoneda(totalMensajes)}}</td>
                        </tr>
                     </table>
               </CCol>
             </CRow>
          </CCardBody>
        </CCard>
      </CCol>
   </CRow>
  
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  name: 'ChartMensajesCampana',
  components: {
    apexchart: VueApexCharts,
  },
  data () {
    return {
      items: [],
      fields: [
        { key: 'dia',label:'Dia'},
        { key: 'mensajes',label:'Mensajes Enviados',},
        { key: 'credito',label:'Creditos Consumidos'},
      ],
      activePage: 1,
    }
  },
  props:{
    dataChartMensajesCampana: Object,
    dataConsultaChart: Object,
  },
  methods: {
    creditosConversorMoneda(value){
      const formatterDolar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
      })
      return (formatterDolar.format(value)).replace('$','');
    },
  },
  computed: {
    totalCelulares(){
      return this.dataChartMensajesCampana['enviados'];
    },
    totalMensajes(){
      return this.dataChartMensajesCampana['total_precio'];
    },
    defaultDatasets () {
      try {
        let array = this.dataChartMensajesCampana['data']['campana']
        for (let index = 0; index < array.length; index++) {
          this.items.push({campana:this.dataChartMensajesCampana['data']['campana'][index],mensajes:this.dataChartMensajesCampana['data']['enviados'][index],credito:this.dataChartMensajesCampana['data']['total'][index]});
        }
      } catch (error) {
      }
      let data1 = [];
      let data2 = [];
      try {
        data1 = this.dataChartMensajesCampana['data']['enviados'] != undefined ? this.dataChartMensajesCampana['data']['enviados'] : [];
        data2 = this.dataChartMensajesCampana['data']['total']  != undefined ? this.dataChartMensajesCampana['data']['total'] : [];
      } catch (error) {
        
      }
      
      return [{
            name: 'Mensajes Enviados',
            type: 'column',
            data: data1,
          }, {
            name: 'Creditos',
            type: 'line',
            data: data2,
          }]
    },
    defaultOptions () {
      let dias = [];
      try {
        dias = this.dataChartMensajesCampana['data']['campana'];
      } catch (error) {
        
      }
        return {
            chart: {
              height: '100%',
              type: 'line',
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false,
              }
            },
            stroke: {
              width: [0, 4]
            },
            title: {
              text: 'Mensajes Enviados Por Campaña'
            },
            dataLabels: {
              enabled: true,
              enabledOnSeries: [1]
            },
            labels: dias,
            xaxis: {
              type: 'category'
            },
            yaxis: [{
              title: {
                text: 'Mensajes Enviados',
              },
            
            }, {
              opposite: true,
              title: {
                text: 'Creditos'
              }
            }]
          }
    },
    defaultOptionsDonut(){
      let dias = [];
      try {
        dias = this.dataChartMensajesCampana['data']['campana'];
      } catch (error) {
        
      }
      return {
            chart: {
              width: '100%',
              type: 'pie',
            },
            labels: dias,
            responsive: [{
              breakpoint: '100%',
              options: {
                chart: {
                  width: '100%'
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          }
    },
    defaultDatasetsDonut(){
      let data1 = [];
      let data2 = [];
      try {
        data1 = this.dataChartMensajesCampana['data']['enviados'] != undefined ? this.dataChartMensajesCampana['data']['enviados'] : [];
        data2 = this.dataChartMensajesCampana['data']['total']  != undefined ? this.dataChartMensajesCampana['data']['total'] : [];
      } catch (error) {
        
      }
      return data1;
    }
  }
}
</script>
