<template>
  <CContainer>
    <template v-if="valida_vista == 1">
      <CRow>
        <CCol col="12">
          <CCard>
            <CCardHeader>
              <h3>Filtro Estadisticas Mailing</h3>
            </CCardHeader>
            <CCardBody>
              <CCardBody>
                <CRow>
                  <CCol col="4">
                    <label type="text" class="col-sm-6"
                      ><span>
                        <strong>Fecha Inicio </strong>
                      </span></label
                    >
                    <input
                      type="date"
                      name="date-radio61"
                      class="form-control"
                      id="fechainicio"
                      v-model="dateinicio"
                      :disabled="fechaHorarioDisabled"
                      @change="obtenerfechainicio"
                    />
                  </CCol>
                  <CCol col="4">
                    <label type="text" class="col-sm-6"
                      ><span>
                        <strong>Fecha Fin </strong>
                      </span></label
                    >
                    <input
                      type="date"
                      name="date-radio62"
                      class="form-control"
                      id="fechafin"
                      :min="minimofechafin"
                      :max="maximofechafin"
                      v-model="datefin"
                      @change="obtenerCambioFechaFin"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol col="4">
                    <label type="text" class="col-sm-9"
                      ><span>
                        <strong>Modo de Envio</strong>
                      </span></label
                    >
                    <CSelect
                      label=""
                      :options="[
                          {
                            value: 'integracion_json',
                            label: 'A traves de cargue de Api Integracion Json',
                          },
                          {
                            value: 'envio_masivo_boletin',
                            label: 'A traves de cargue masivos'
                          },
                          {
                            value: 'todos',
                            label: 'Todos los de mis envios y sub Cuentas creadas',
                          }
                      ]"
                      required
                      was-validated
                      @change="filtroProyecto"
                      :value.sync="tipo_reporte"
                    >
                    </CSelect>
                  </CCol>
                  <CCol col="4">
                    <label type="text" class="col-sm-9"
                      ><span>
                        <strong>Sub Cuentas</strong>
                      </span></label
                    >
                    <CSelect
                      label=""
                      :options="itemsSubCuentas"
                      was-validated
                      :value.sync="usuario"
                      @change="filtroProyecto"
                      :disabled="desahabilitarModoEnvioSubCuenta"
                    >
                    </CSelect>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol col="4">
                    <label type="text" class="col-sm-9"
                      ><span>
                        <strong>Campaña</strong>
                      </span></label
                    >
                    <multiselect
                      v-model="proyecto"
                      placeholder="Busca y Selecciona la Campaña"
                      :disabled="desahabilitarModoEnvio"
                      label="name"
                      track-by="code"
                      :options="itemsProyectos"
                      :multiple="true"
                      :taggable="true"
                    ></multiselect>
                    <!--<CSelect
                        label=""
                        :options="itemsProyectos"
                        was-validated
                        :value.sync="proyecto"
                        :disabled="desahabilitarModoEnvio"
                        >
                    </CSelect>-->
                  </CCol>
                </CRow>
                <CRow>
                  <CCol col="4">
                    <br />
                    <button
                      type="button"
                      class="btn btn-primary btn-lg btn-block"
                      v-if="$can('reportes.index')"
                      @click="generarReportes()"
                    >
                      Generar
                    </button>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </template>
    <template v-if="valida_vista == 2">
      <EstadisticasMail :dataConsultaChart="dataConsultaChart" />
    </template>
    <div>
      <CToaster :autohide="5000" class="d-inline-block">
        <template v-for="toast in mostrarNotificacion">
          <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            header="Notificacion Exitosa"
          >
            {{ mensaje_success }}
          </CToast>
        </template>
        <template v-for="toast1 in mostrarNotificacionAlerta">
          <CToast
            :key="'toast1' + toast1"
            :show="true"
            color="warning"
            header="Notificacion Alerta"
          >
            {{ mensaje_warning }}
          </CToast>
        </template>
        <template v-for="toast2 in mostrarNotificacionError">
          <CToast
            :key="'toast2' + toast2"
            :show="true"
            color="danger"
            header="Notificacion Alerta"
          >
            Error Comunicarse Con Soporte
          </CToast>
        </template>
      </CToaster>
    </div>
  </CContainer>
</template>


<script>
import Multiselect from "vue-multiselect";
import EstadisticasMail from "./EstadisticasMail.vue";
export default {
  name: "FiltroEstadisticasMail",
  components: { Multiselect, EstadisticasMail },
  data() {
    return {
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError: 0,
      mensaje_warning: "Debe Completar Los Datos Vacio",
      mensaje_success: "Grupo Creado Correctamente.",
      itemsProyectos: [],
      itemsSubCuentas: [],
      labelsChart: [],
      dataChart: [],
      dateinicio: null,
      datefin: null,
      ReportesSelect: null,
      fechaHorarioDisabled: null,
      minimofechafin: null,
      maximofechafin: null,
      desahabilitarModoEnvio: true,
      desahabilitarModoEnvioSubCuenta: true,
      tipo_reporte: "integracion_json",
      proyecto: "",
      usuario: 0,
      valida_vista: 1,
      dataConsultaChart: {},
    };
  },
  computed: {
    defaultDatasets() {
      return [
        {
          backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
          data: this.dataChart,
        },
      ];
    },
  },
  methods: {
    obtenerfechainicio() {
      const now = new Date(this.dateinicio);
      const today = now;
      const maxDate = new Date(today);
      const maxDate2 = new Date(today);
      const minDate = new Date(today);
      maxDate.setDate(now.getDate() + 366);
      maxDate2.setDate(now.getDate() + 31);
      minDate.setDate(now.getDate() + 1);
      //console.log(maxDate);
      //console.log(minDate);
      let fecha_fin = `${maxDate2.getFullYear()}-${
        maxDate2.getMonth() + 1 < 10
          ? "0" + (maxDate2.getMonth() + 1)
          : maxDate2.getMonth() + 1
      }-${
        maxDate2.getDate() < 10 ? "0" + maxDate2.getDate() : maxDate2.getDate()
      }`;
      this.minimofechafin = `${minDate.getFullYear()}-${
        minDate.getMonth() + 1 < 10
          ? "0" + (minDate.getMonth() + 1)
          : minDate.getMonth() + 1
      }-${
        minDate.getDate() < 10 ? "0" + minDate.getDate() : minDate.getDate()
      }`;
      this.maximofechafin = `${maxDate.getFullYear()}-${
        maxDate.getMonth() + 1 < 10
          ? "0" + (maxDate.getMonth() + 1)
          : maxDate.getMonth() + 1
      }-${
        maxDate.getDate() < 10 ? "0" + maxDate.getDate() : maxDate.getDate()
      }`;
      this.datefin = fecha_fin; //this.maximofechafin
      //console.log(this.minimofechafin);
      //console.log(this.maximofechafin);
      this.obtenerproyectoPorfecha();
      this.obtenerSubcuentas();
    },
    obtenerproyectoPorfecha() {
      //console.log(this.usuario);
      if (
        this.dateinicio != null &&
        this.datefin != null &&
        this.tipo_reporte != "todos" &&
        this.usuario != 0
      ) {
        this.$store.dispatch("auth/loading_http", "visible");
        this.$http
          .post("/mail/reporte/lista/proyectos", {
            url:"/mail/reporte/lista/proyectos",
            fechainicio: this.dateinicio,
            fechafin: this.datefin,
            tipo_reporte: this.tipo_reporte,
            iduser: this.usuario,
          })
          .then((response) => {
            let data = response.data;
            if (data.length > 0) {
              this.desahabilitarModoEnvio = false;
              this.itemsProyectos = [];
              //this.itemsProyectos.push({code:0,name:'Seleccionar Campaña'})
              for (const iterator of data) {
                this.itemsProyectos.push({
                  code: iterator.idproyecto,
                  name: iterator.proyecto,
                });
              }
            } else {
              this.desahabilitarModoEnvio = true;
              this.itemsProyectos = [];
              this.proyecto = "";
            }
          })
          .catch((e) => {
            this.mostrarNotificacionError++;
          })
          .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      } else {
        this.desahabilitarModoEnvio = true;
        this.itemsProyectos = [];
        this.proyecto = "";
      }
    },
    obtenerlistaReportesGenerados() {
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/mail/reportes",{
          url:"/mail/reportes",
        })
        .then((response) => {
          this.items = response.data;
          this.items.map((item, id) => {
            if (id % 2 == 0) {
              item["_classes"] = "table-now";
            }
            return item;
          });
        })
        .catch((e) => {
          this.mostrarNotificacionError++;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    obtenerCambioFechaFin() {
      //console.log(this.dateinicio,this.datefin);
      this.obtenerproyectoPorfecha();
      this.obtenerSubcuentas();
      this.filtroProyecto();
    },
    filtroProyecto() {
      if (
        this.dateinicio != null &&
        this.datefin != null &&
        this.tipo_reporte != "todos"
      ) {
        //this.desahabilitarModoEnvio = false;
        this.desahabilitarModoEnvioSubCuenta = false;
      } else if (
        this.dateinicio != null &&
        this.datefin != null &&
        this.tipo_reporte == "todos"
      ) {
        //this.desahabilitarModoEnvio = true;
        if (this.itemsSubCuentas.length > 50) {
          this.desahabilitarModoEnvioSubCuenta = false;
        } else {
          this.desahabilitarModoEnvioSubCuenta = true;
          this.proyecto = "";
          this.usuario = 0;
        }
      } else {
        //this.desahabilitarModoEnvio = true;
        this.desahabilitarModoEnvioSubCuenta = true;
        this.proyecto = "";
        this.usuario = 0;
      }
      this.obtenerSubcuentas();
      this.obtenerproyectoPorfecha();
    },
    creditosConversorMoneda(value) {
      const formatterDolar = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatterDolar.format(value).replace("$", "");
    },
    obtenerSubcuentas() {
      if (
        this.dateinicio != null &&
        this.datefin != null &&
        this.tipo_reporte != "todos"
      ) {
        this.$store.dispatch("auth/loading_http", "visible");
        this.$http
            .post("/mail/reporte/lista/subcuentas", {
              url:"/mail/reporte/lista/subcuentas",
              action: 1,
          })
          .then((response) => {
            let data = response.data;
            if (data.length > 0) {
              this.desahabilitarModoEnvioSubCuenta = false;
              this.itemsSubCuentas = [];
              this.itemsSubCuentas.push({
                value: 0,
                label: "Seleccionar Usuario",
              });
              for (const iterator of data) {
                this.itemsSubCuentas.push({
                  value: iterator.id,
                  label: iterator.name + ` (${iterator.padre})`,
                });
              }
            } else {
              this.desahabilitarModoEnvioSubCuenta = true;
              this.itemsSubCuentas = [];
              this.usuario = 0;
            }
          })
          .catch((e) => {
            this.mostrarNotificacionError++;
          })
          .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      }
    },
    generarReportes() {
      if (
        this.dateinicio != null &&
        this.datefin != null &&
        this.tipo_reporte != ""
      ) {
        let datafecha = {
          fechainicio: this.dateinicio,
          fechafin: this.datefin,
          tipo_reporte: this.tipo_reporte,
          idproyecto: this.proyecto,
          iduser: this.usuario,
          url:"/mail/valida/chart"
        };
        this.$store.dispatch("auth/loading_http", "visible");
        this.$http
          .post("/mail/valida/chart", datafecha)
          .then((response) => {
            let data = response.data;
            if (data == "OK") {
              this.dataConsultaChart = datafecha;
              this.valida_vista = 2;
            } else {
              this.mostrarNotificacionAlerta++;
              this.mensaje_warning =
                "Las Fechas Consultadas No Contienen Registros";
            }
          })
          .catch((e) => {
            this.mostrarNotificacionError++;
          })
          .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      } else {
        this.mostrarNotificacionAlerta++;
        this.mensaje_warning = "Debe Completar Los Datos Vacio";
      }
    },
  },
  mounted() {
    setTimeout(() => {}, 100);
    this.$root.$on("clickedSomething", (param) => {
      this.valida_vista = param;
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
